<template>
  <div>
    <md-dialog :md-active.sync="isFavModalOpen" class="md-dialog-tab fav-line-cell-modal" v-if="isFavModalOpen">
      <md-dialog-title class="modal-header"> {{ isEditableLineCell ? 'Edit' : 'New' }} Favline Cell
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
        >
          <span class="material-icons">close</span>
        </button>
      </md-dialog-title>
      <form novalidate class="fav-cell-modal"
            @submit.prevent="validateBeforeSave" enctype="multipart/form-data">
        <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="isLoading"/>
        <md-content class="modal-body"
                    id="modalDescription">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field :class="[getValidationClass('domain'),'md-field-site']">
                <input class="modal-input form-control input-with-border" name="domain"
                       id="domain"
                       type="text"
                       v-model="form.domain"
                       @keyup="selectSite"
                       placeholder="Domain"
                />
                <div class="md-error" v-if="!$v.form.domain.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
              <md-field :class="[getValidationClass('url'),'md-field-site']">
                <input class="modal-input form-control input-with-border" name="url"
                       id="url"
                       type="text"
                       v-model="form.url"
                       placeholder="Url"
                />
                <div class="md-error" v-if="!$v.form.url.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
              <md-field :class="[getValidationClass('alt'),'md-field-site']">
                <input class="modal-input form-control input-with-border" name="alt"
                       id="alt"
                       type="text"
                       v-model="form.alt"
                       placeholder="Alt"
                />
                <div class="md-error" v-if="!$v.form.alt.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
              <input class="modal-input form-control input-with-border" name="tags"
                     id="tags"
                     type="text"
                     v-model="form.tags"
                     placeholder="Tags"
              />
              <md-field :class="[getValidationClass('quality'),'md-field-site']">
                <input class="modal-input form-control input-with-border" name="quality"  :maxlength="maxLength"
                       id="quality"
                       type="number"
                       v-model="form.quality"
                       placeholder="Quality"
                />
                <div class="md-error" v-if="!$v.form.quality.required">
                  {{ $t('missingValue') }}
                </div>
              </md-field>
            </div>
            <div class="md-layout-item">
              <input class="modal-input form-control input-with-border" name="blogLink"
                     id="blogLink"
                     type="text"
                     v-model="form.blog_link"
                     placeholder="Blog Link"
              />
              <input class="modal-input form-control input-with-border" name="secondary_url"
                     id="secondary_url"
                     type="text"
                     v-model="form.secondary_url"
                     placeholder="Secondary Url"
              />
              <input class="modal-input form-control input-with-border" name="name"
                     id="name"
                     type="text"
                     v-model="form.name"
                     placeholder="Name"
              />
              <input class="modal-input form-control input-with-border" name="blog_paragraph"
                     id="blog_paragraph"
                     type="text"
                     v-model="form.blog_paragraph"
                     placeholder="Blog Paragraph"
              />
              <div style="display: flex; flex-flow: row">
                  <i class="far fa-image" @click="$refs.file.click()" style="margin: 0 auto; padding-top: 20px;">
                    <p class="icons-description"> image </p>
                  </i>
                  <input type="file" ref="file" id="file" class="filestyle" data-count="0" style="display: none;"
                         @change="onFileChange">
                <md-checkbox v-model="form.affiliated" style="margin: 0 auto; padding-top: 30px;">Affiliated</md-checkbox>
              </div>
            </div>
            <div class="image-preview" style="width: 100%">
              <img class="cell-image-preview" :src="imagePreview" v-show="imagePreview">
            </div>
          </div>
        </md-content>

        <md-dialog-actions class="modal-footer">
          <div class="md-layout md-buttons-modal md-layout-flex-1">
            <md-button class="md-raised md-primary site-save-button" type="submit" style="margin-bottom: 30px;">
              {{ $t('userTopTabs.save') }}
            </md-button>
          </div>
        </md-dialog-actions>
      </form>

    </md-dialog>
  </div>
</template>


<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { ADMIN_CREATE_FAV_LINE_CELL, ADMIN_EDIT_FAV_LINE_CELL } from '@/store/modules/admin/single/favPage/favPageActions.type';
import { CHECK_SITE } from '@/store/modules/admin/single/site/siteActions.type';

export default {
  name: 'FavLineCellModal',
  props: {
    isFavModalOpen: Boolean,
    isEditableLineCell: {
      type: [Object, NaN],
    },
    favLineObj: {
      type: [Object, NaN],
    },
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      id: null,
      domain: null,
      url: null,
      alt: null,
      tags: null,
      tags_update: null,
      affiliated: false,
      quality: null,
      blog_link: null,
      secondary_url: null,
      name: null,
      blog_paragraph: null,
      is_site: false,
      image: null,
    },
    requiredFields: false,
    lineId: null,
    maxLength: 2,
    imagePreview: null,
    validUrl: false,
    timeout: null,
  }),
  computed: {
    favPageData() {
      return this.$store.getters.adminFavPageData;
    },
  },
  validations: {
    form: {
      domain: {
        required,
      },
      url: {
        required,
      },
      alt: {
        required,
      },
      quality: {
        required,
      },
    },
  },
  mounted() {
    this.getModal();
  },
  methods: {
    selectSite() {
      clearTimeout(this.timeout);
      if (this.urlValidation(this.form.domain)) {
        this.validUrl = false;
        this.timeout = setTimeout(() => {
          const filters = {
            link: this.form.domain,
          };
          this.$store.dispatch(CHECK_SITE, filters)
            .then((response) => {
              const item = response.data.site;
              if (item) {
                this.imagePreview = item ? item.public_path : null;
                this.form.image = item ? item.iconpath : null;
                this.form.is_site = true;
              }
            })
            .catch((error) => {
              this.$log.error('CHECK_SITE', error);
            });
        }, 800);
      } else {
        this.validUrl = true;
      }
    },
    urlValidation(url) {
      if (url.match(/(http(s)?:\/\/.)?(www\.)?(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i)) {
        return true;
      }
      return false;
    },
    onFileChange(e) {
      const image = e.target.files[0];
      this.form.image = image;
      this.createImage(image);
      this.form.is_site = false;
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getModal() {
      if (this.isCellModalVisible) {
        this.isCellModalVisibleOn = true;
      } else {
        this.isCellModalVisibleOn = false;
      }
    },
    validateBeforeSave() {
      this.$v.$touch();
      this.errors = {};
      if (!this.$v.$invalid) {
        this.save();
      } else {
        this.requiredFields = true;
      }
    },
    save() {
      const payload = {
        id: this.form.id,
        favPageId: this.favPageData.id.slice(0, -1),
        lineId: this.favLineObj.id,
      };
      const formData = new FormData();
      formData.append('domain', this.form.domain);
      formData.append('image', this.form.image);
      formData.append('url', this.form.url);
      formData.append('alt', this.form.alt);
      formData.append('tags', this.form.tags);
      formData.append('tags_update', this.form.tags_update);
      formData.append('affiliated', this.form.affiliated);
      formData.append('quality', this.form.quality);
      formData.append('blog_link', this.form.blog_link);
      formData.append('secondary_url', this.form.secondary_url);
      formData.append('name', this.form.name);
      formData.append('blog_paragraph', this.form.blog_paragraph);
      formData.append('context', 'FAV_LINE_CELL_IMAGE');
      formData.append('is_site', this.form.is_site);

      this.$store.dispatch(this.isEditableLineCell ? ADMIN_EDIT_FAV_LINE_CELL : ADMIN_CREATE_FAV_LINE_CELL, { formData, payload })
        .then((response) => {
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
          this.$emit('reloadData');
        })
        .catch((error) => {
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    close() {
      this.$v.$reset();
      this.$emit('close');
    },
    clearForm() {
      this.close();
      this.$v.$reset();
    },
  },
  watch: {
    isEditableLineCell: {
      handler(obj) {
        if (obj) {
          this.form.id = obj.id;
          this.form.name = obj.name;
          this.form.domain = obj.domain;
          // this.form.image = obj.public_path;
          this.imagePreview = obj.public_path;
          this.form.url = obj.url;
          this.form.alt = obj.alt;
          this.form.tags = obj.tags;
          this.form.tags_update = obj.tags_update;
          this.form.quality = obj.quality;
          this.form.affiliated = obj.affiliated;
          this.form.blog_link = obj.blog_link;
          this.form.secondary_url = obj.secondary_url;
          this.form.blog_paragraph = obj.blog_paragraph;
          this.form.is_site = false;
          this.form.image = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/favCell-modal.scss';
</style>
